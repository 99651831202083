import { weatherService } from "../../services/weather.service";

const state = {};

const actions = {
  async GetMessage({}, { condition }) {
    let result = await weatherService.GetMessage(condition);
    return result;
  },
  async GetWeather({}, { farmId, type }) {
    switch (type) {
      case "Current":
        return await weatherService.CurrentConditions(farmId);
      case "DailyForecast":
        return await weatherService.DailyForecast(farmId);
      case "History":
        return await weatherService.History(farmId);
      case "HourlyForcast":
        return await weatherService.HourlyForcast(farmId);
      default:
        return null;
    }
  },
};
const mutations = {};
export default {
  namespaced: true,
  weatherState: state,
  actions,
  mutations,
};
