import axios from "axios";
import {
  apiMeteorologyGetMessage,
  apiMeteorologyCurrentConditions,
  apiMeteorologyDailyForecast,
  apiMeteorologyHistory,
  apiMeteorologyHourlyForcast,
} from "../constants/config";
import "core-js/stable";

async function GetMessage(condition) {
  try {
    let data = {
      Temperature: condition.temp,
      MaxTemperature: condition.maxTemp,
      MinTemperature: condition.minTemp,
      Humidity: condition.humidity,
      WindSpeed: condition.windSpeed,
      ChanceOfRain: condition.chanceOfRine,
      visibility: condition.visibility,
    };
    let promise = await axios
      .post(apiMeteorologyGetMessage, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from weather" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

async function CurrentConditions(farmId) {
  try {
    let data = {
      FarmId: farmId,
    };
    let promise = await axios
      .post(apiMeteorologyCurrentConditions, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from weather" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
} 
async function DailyForecast(farmId) {
  try {
    let data = {
      FarmId: farmId,
    };
    let promise = await axios
      .post(apiMeteorologyDailyForecast, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from weather" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function History(farmId) {
  try {
    let data = {
      FarmId: farmId,
    };
    let promise = await axios
      .post(apiMeteorologyHistory, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from weather" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function HourlyForcast(farmId) {
  try {
    let data = {
      FarmId: farmId,
    };
    let promise = await axios
      .post(apiMeteorologyHourlyForcast, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from weather" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

export const weatherService = {
  GetMessage,
  CurrentConditions, 
  DailyForecast,
  History,
  HourlyForcast,
};
